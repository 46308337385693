import React from 'react';
import Panorama from '../components/panorama/Panorama';

const Banner3d = () => {
  return (
    <div className='auth-container login'>
      <Panorama />
    </div>
  );
};

export default Banner3d;
