import { useState } from 'react';
import Logo from '../../assets/images/logo-white.png';
import LogoBlack from '../../assets/images/logo.png';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Scrollspy from 'react-scrollspy';

function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isNavbar, setIsNavbar] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const setUtmTags = () => {
    const utm = window.location.search;
    const hasUtmTags = utm.length > 0;

    // If utm exists,
    if (!hasUtmTags) {
      return;
    }

    localStorage.setItem('utm_tags', utm);
  };

  setUtmTags();

  const changeBackground = () => {
    if (window.scrollY > 30) {
      setIsNavbar(true);
    } else {
      setIsNavbar(false);
    }
  };

  const scrollToSection = (e) => {
    const targetHash = e.currentTarget.hash;
    if (document.querySelector(targetHash)) {
      e.preventDefault();
      const top = document.querySelector(targetHash).offsetTop;
      window.scroll({
        top: top - 40,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const logoutSession = (e) => {
    localStorage.removeItem('session');
    localStorage.removeItem('APPLICATION-TOKEN');
    navigate('/login');
  };

  // const launchEditor = (e) => {
  //   api.headers(false, true).then(() => {
  //     api.method('post', 'generateCrossLoginToken').then((res) => {
  //       const data = res.data.data;

  //       window.open(
  //         `https://cloudexpo.hiverlab.com?cl_token=${data.token}`,
  //         '_blank'
  //       );
  //     });
  //   });
  // };

  const initSectionActivation = () => {
    const windowHeightPart = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );

    document.querySelectorAll('.js-section').forEach((element) => {
      const { offsetTop } = element;

      if (offsetTop < window.scrollY + windowHeightPart) {
        element.classList.add('section--active');
      }
    });
  };

  initSectionActivation();

  window.addEventListener('scroll', () => {
    changeBackground();
    initSectionActivation();
  });

  useEffect(() => {
    if (localStorage.getItem('session')) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <>
      <header
        className={`nav-wrapper js-nav-wrapper ${
          isNavbar || props.forcedFixed ? 'is--fixed' : ''
        }`}
      >
        <div className='container-fluid'>
          <nav className='navbar navbar-expand-md navbar-dark'>
            <a className='navbar-brand' href='/#home' onClick={scrollToSection}>
              <img src={Logo} className='logo--white' alt='Logo' />
              <img src={LogoBlack} className='logo--black' alt='Logo' />
            </a>
            {/* <div className="language-selector order-sm-0 order-md-1">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <img src={UsFlag} alt="English" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/">
                  <img src={UsFlag} alt="English" />English
                </Dropdown.Item>
                <Dropdown.Item href="https://cloudexpo.dev/vn.html">
                  <img src={VnFlag} alt="Vietnam" />Vietnamese
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            </div> */}
            <button
              type='button'
              className={`navbar-toggler ${!isOpen ? 'collapsed' : ''}`}
              onClick={() => setIsOpen(!isOpen)}
            >
              <i></i>
              <i></i>
            </button>
            <div
              className='navbar-collapse'
              id='headerNavMenu'
              style={{
                maxHeight: isOpen ? 700 : 0,
                marginTop: isOpen ? 16 : 0,
              }}
            >
              <Scrollspy
                items={[
                  'about',
                  'features',
                  'casestudies',
                  'pricing',
                  'contact',
                ]}
                offset={-100}
                currentClassName='active'
                className='navbar-nav ml-auto align-items-center'
              >
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    onClick={scrollToSection}
                    href='/#about'
                  >
                    {' '}
                    About Us
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    onClick={scrollToSection}
                    href='/#features'
                  >
                    Features
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    onClick={scrollToSection}
                    href='/#casestudies'
                  >
                    Case Studies
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    onClick={scrollToSection}
                    href='/#pricing'
                  >
                    Pricing
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    onClick={scrollToSection}
                    href='/#contact'
                  >
                    Contact
                  </a>
                </li>
              </Scrollspy>

              <ul className='navbar-nav ml-auto align-items-center'>
                {!isLoggedIn && (
                  <li className='nav-item'>
                    <NavLink to='/login' className='nav-link'>
                      Login
                    </NavLink>
                  </li>
                )}
                {!isLoggedIn && (
                  <li className='nav-item'>
                    <Link
                      to='/register/free'
                      className='c-btn c-btn-gradient mr-2'
                      style={{
                        fontSize: '13px',
                        padding: '10px 19px',
                        minWidth: 'auto',
                      }}
                    >
                      <span className='c-btn-text'>Get Started</span>
                    </Link>
                  </li>
                )}
                {isLoggedIn && location.pathname !== '/account' && (
                  <li className='nav-item'>
                    <Link
                      to='/account'
                      className='c-btn c-btn-gradient mr-2'
                      style={{
                        fontSize: '13px',
                        padding: '10px 19px',
                        minWidth: 'auto',
                      }}
                    >
                      <span className='c-btn-text'>Go to Account</span>
                    </Link>
                  </li>
                )}
                {isLoggedIn && (
                  <li className='nav-item'>
                    <div className='nav-link' onClick={logoutSession}>
                      Logout
                    </div>
                  </li>
                )}
              </ul>
            </div>
            {/* <button
              className="navbar-toggler collapsed"
              type="button"
              datatoggle="collapse"
              datatarget="#collapsibleNavbar"
            >
              <i></i>
              <i></i>
            </button> */}
            {/* <div className="collapse navbar-collapse" id="collapsibleNavbar">
              
            </div> */}
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
