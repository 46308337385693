function AboutSection() {
  return (
    <>
      <section
        id='about'
        className='section-about section-title section-title-centered section-slide js-section'
      >
        <div className='container'>
          <div className='ce-intro'>
            <div className='row align-items-center'>
              <div className='col-lg-12'>
                <div data-content-title='About' className='ce-intro-content'>
                  <span className='tagline two-lined slide-up justify-content-center'>
                    A VIRTUAL SPACE CREATOR FOR ENTERPRISE
                  </span>
                  <h2
                    style={{ transitionDelay: '0.6s' }}
                    className='slide-up-heading'
                  >
                    <span className='font-thin'>About&nbsp;</span>
                    <span className='blue-highlight'>CloudExpo</span>
                  </h2>

                  <blockquote
                    style={{ transitionDelay: '0.9s' }}
                    className='slide-up'
                  >
                    <span className='blue-highlight'>Cloud</span>Expo is a
                    web-based platform designed to help organizations create
                    engaging virtual experiences for learning, visualization,
                    and presentation purposes. It offers a collaborative content
                    creation process and flexible deployment options, making it
                    ideal for enterprise use. The platform further enhances the
                    user experience with its multi-user audio calls, live chat,
                    3d space and interactive features.
                  </blockquote>
                </div>
              </div>
              <div className='col-lg-12 mt-5'>
                <div
                  style={{ transitionDelay: '0.9s' }}
                  className='ce-intro-artwork slide-up'
                >
                  <div className='iframe-container'>
                    <iframe
                      width='100%'
                      height='600'
                      src='https://www.youtube.com/embed/wnc-O_AWsmc'
                      title='About CloudExpo'
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutSection;
