/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MyVerticallyCenteredModal from '../Modal/modal';
import SinglePlan from '../Plans/singlePlan';
import fallbackPlan from '../../assets/data/fallback-plan';
import {
  getUserInfo,
  formatDate,
  prepareErrorMessage,
  validity,
  bytesToSize,
  isLoggedIn,
} from '../../utils/Helper';
import { api } from '../../interceptor/interceptor';
import toast from 'react-hot-toast';
import { GeneralLogic } from '../../interceptor/generalLogic';

function AccountDetails() {
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);

  // Holds Data
  const [projectsCreated, setProjectsCreated] = useState(0);
  const [planDetails, setPlanDetails] = useState(fallbackPlan);
  const [userDetails, setUserDetails] = useState({});
  const [changeEmailRequest, setChangeEmailRequest] = useState({});
  const [changePasswordRequest, setChangePasswordRequest] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [isTouched, setIsTouched] = useState(false);
  const [isYearly, setIsYearly] = useState(true);
  const [planStatus, setPlanStatus] = useState('active');

  // const [cardNo, setCardNo] = useState("");
  // const [cvcNo, setCvcNo] = useState("");
  // const [expiry, setExpiry] = useState("");

  const { appPricingPlans } = GeneralLogic();
  const plans = appPricingPlans;

  const fetchPlanDetails = async () => {
    const userInfo = getUserInfo();
    if (!userInfo) return;
    const requestBody = { user_id: userInfo?.user_id };
    await api.headers(true).then(async () => {
      await api
        .method('post', 'membership/getUserPlan', requestBody)
        .then((res) => {
          let planReponse = res.data.data.user_plan[0];
          if (planReponse) {
            planReponse.asset_size = res.data.data.asset_size * 1e9;
            planReponse.plan.plan_details = JSON.parse(
              planReponse.plan.plan_details
            );
            setPlanDetails(planReponse);
            if (res.data.data.Subscription) {
              setPlanStatus(res.data.data.Subscription.toLowerCase());
            }
            const isYearlyPlan = planReponse.subscription_period === 'yearly';
            setIsYearly(isYearlyPlan);
          }
        })
        .catch((err) => {
          const message = err.response
            ? err.response.data
            : "Request couldn't be completed!";
          prepareErrorMessage(message);
        });
    });

    getProjectsCount();
  };

  const fetchUserDetails = async () => {
    const userInfo = getUserInfo();
    if (!userInfo) return;
    const requestBody = { user_id: userInfo.user_id };
    await api.headers(false, true).then(async () => {
      await api
        .method('post', 'getProfileInfo', requestBody)
        .then((res) => {
          setUserDetails(res.data.data);
        })
        .catch((err) => {
          prepareErrorMessage(err.response.data);
        });
    });
  };

  const submitChangeDetails = async () => {
    setIsTouched(true);
    await api.headers(false, true).then(async () => {
      await api
        .method('post', 'user/updateUser', changeEmailRequest)
        .then((res) => {
          toast.success(
            res.data.message || 'Account information has been updated!'
          );
          setModalShow2(false);
          fetchUserDetails();
        })
        .catch((err) => {
          prepareErrorMessage(err.response.data);
        });
    });
  };

  const submitChangePassword = async () => {
    setIsTouched(true);
    await api.headers(false, true).then(async () => {
      await api
        .method('post', 'user/changePassword', changePasswordRequest)
        .then((res) => {
          toast.success(res.data.message || 'Password has been updated!');
          setModalShow3(false);
        })
        .catch((err) => {
          prepareErrorMessage(err.response.data);
        });
    });
  };

  const checkValidity = (key, value) => {
    let fieldsValidity;
    switch (key) {
      case 'email':
        fieldsValidity = validity(
          { required: true, regexPattern: 'email' },
          value
        );
        break;
      case 'password':
        fieldsValidity = validity(
          { required: true, regexPattern: 'password' },
          value
        );
        break;
      default:
        fieldsValidity = validity({ required: true }, value);
        break;
    }
    if (!fieldsValidity.success) {
      let errorMessageObject = {};
      errorMessageObject[key] = fieldsValidity;
      setErrorMessage({
        ...errorMessage,
        ...errorMessageObject,
      });
    } else {
      delete errorMessage[key];
    }
  };

  const intializePaymentSession = async (
    planId,
    isEnterprise = false,
    isOnPaidPlan = false
  ) => {
    const availablePlans = {
      MrY0EolVw9NJj8QpGDGa1keRp4O2ZyA3: 'free',
      bpkOdenP6vyqZ0KMzDElR1M78Wao34Jj: 'basic',
      PkE4Jb7Oqx2ZBvKP1DyaY8n9j01o3emV: 'pro',
    };

    // TODO: check if the planId is valid or not
    if (!availablePlans[planId]) {
      toast.error('Not a valid plan');
      return;
    }

    // Link to contact form if enterprise
    if (isEnterprise) {
      window.location.href = 'https://cloudexpo.dev/#contact';
      return;
    }

    // If the user is currently on a paid plan
    if (isOnPaidPlan) {
      const confirmation = window.confirm(
        'Are you sure you want to change your plan?'
      );
      if (confirmation) {
        const requestBody = {
          plan_name: availablePlans[planId], // TODO: Pass selected plan name into this parameter (Free, Basic, Pro)
          membership_history_id: planDetails.id,
        };
        await api.headers(false, true).then(async () => {
          await api
            .method('post', 'membership/updateSubscription', requestBody)
            .then((res) => {
              toast.success(
                `Your subscription plan has been updated successfully!`
              );

              // TODO: Handle UI here
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((err) => {
              toast.error(
                'Something went wrong while updating your plan! Please try again later.'
              );
            });
        });
      }

      return;
    }

    const confirmation = window.confirm(
      'Are you sure you want to change your plan?'
    );
    if (confirmation) {
      const interval = isYearly ? 'year' : 'month';
      const requestBody = { interval, plan_id: planId };
      await api.headers(false, true).then(async () => {
        await api
          .method('post', 'membership/createSession', requestBody)
          .then((res) => {
            const data = res.data.data;
            const redirectURL = data[0].url;
            const sessionId = data[0].id;
            localStorage.setItem(
              'PAYMENT-SESSION',
              JSON.stringify({ session_id: sessionId, plan_id: planId })
            );
            toast.success(
              'Payment has been initialized, redirecting you in 3..2..1!'
            );

            setTimeout(() => {
              window.location.href = redirectURL;
            }, 3000);
          });
      });
    }
  };

  const intializePlanCancellation = async () => {
    const confirmation = window.confirm(
      'Are you sure you want to cancel your plan?'
    );
    if (confirmation) {
      const params = { membership_history_id: planDetails.id };
      await api.headers(false, true).then(async () => {
        await api
          .method('post', 'membership/cancelSubscription', null, { params })
          .then((res) => {
            toast.success('Plan has been successfully cancelled!');
            fetchPlanDetails();
            setModalShow1(false);
          })
          .catch((err) => {
            prepareErrorMessage(err.response.data);
          });
      });
    }
  };

  const getProjectsCount = (body) => {
    const userInfo = getUserInfo();
    if (!userInfo) return;
    const params = { user_id: userInfo.user_id };
    api
      .method('post', 'getProjectCount', body, {
        baseURL: process.env.REACT_APP_CLOUDEXPO_API_BASE_URL,
        params,
      })
      .then((res) => {
        if (res.data.data !== '') {
          setProjectsCreated(res.data.data);
        } else {
          setProjectsCreated(0);
        }
      });
  };

  useEffect(() => {
    fetchPlanDetails();
    fetchUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container className='customHeight'>
        <Row
          className='d-flex align-items-center justify-content-center'
          style={{ minHeight: '100vh' }}
        >
          <Col>
            <div className='ce-wrapper'>
              <div className='ce-header'>
                <h6>Account Subscription</h6>
                {planStatus === 'cancelled' && (
                  <div className='ce-bd-wrapper'>
                    Plan Status:
                    <span className='badge badge-pill badge-danger'>
                      {planStatus}
                    </span>
                  </div>
                )}
              </div>
              <div className='ce-body'>
                <Row>
                  <Col xs style={{ borderRight: '1px solid grey' }}>
                    <h6>
                      Plan:{' '}
                      {planDetails.plan ? planDetails.plan.plan_name : 'N/A'}
                    </h6>
                    <ButtonToolbar>
                      <ButtonGroup>
                        <Button
                          className='c-btn c-btn-gradient'
                          onClick={() => setModalShow1(true)}
                        >
                          <span className='c-btn-text'>Change Plan</span>
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Col>
                  <Col xs lg='9'>
                    <div>
                      <h6>
                        Validity:&nbsp;
                        {formatDate(
                          planDetails.start_date,
                          'DD MMM YYYY'
                        )} -{' '}
                        {planDetails.end_date
                          ? formatDate(planDetails.end_date, 'DD MMM YYYY')
                          : 'Forever'}
                        <br />
                        {planStatus === 'cancelled' && (
                          <small className='text-danger'>
                            Current plan will be downgraded to{' '}
                            <strong>Free Plan</strong> effective from{' '}
                            <strong>
                              {formatDate(planDetails.end_date, 'DD MMM YYYY')}
                            </strong>
                          </small>
                        )}
                      </h6>

                      <h6>
                        Number of project(s): {projectsCreated}{' '}
                        {planDetails.plan?.plan_details.allowed_projects !== -1
                          ? `of ${planDetails.plan?.plan_details.allowed_projects}`
                          : ''}
                      </h6>
                      <h6>
                        Cloud Storage Usage:{' '}
                        {bytesToSize(planDetails.asset_size)} of{' '}
                        {planDetails.plan?.plan_details.allowed_storage} GB
                      </h6>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className='ce-wrapper'>
              <div className='ce-header'>
                <h6>Profile Details</h6>
              </div>
              <div className='ce-body'>
                <Row>
                  <Col>
                    <p>First Name</p>
                    <h6>
                      {userDetails.full_name
                        ? userDetails.full_name.split(' ')[0]
                        : 'N/A'}
                    </h6>
                  </Col>
                  <Col>
                    <p>Last Name</p>
                    <h6>
                      {userDetails.full_name
                        ? userDetails.full_name.split(' ')[1]
                        : 'N/A'}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>E-Mail Adress</p>
                    <span style={{ fontWeight: '500' }}>
                      {userDetails.email}
                    </span>
                    <span>
                      <div
                        className='text-muted muted-text-custom'
                        onClick={() => setModalShow2(true)}
                        style={{
                          fontSize: '13px',
                          textDecoration: 'underline',
                          marginLeft: '10px',
                        }}
                      >
                        Change
                      </div>
                    </span>
                  </Col>
                  <hr />
                  <Col>
                    <p>Password</p>
                    <span style={{ fontWeight: '500' }}>********</span>
                    <span>
                      <div
                        className='text-muted muted-text-custom'
                        onClick={() => setModalShow3(true)}
                        style={{
                          position: 'relative',
                          fontSize: '13px',
                          textDecoration: 'underline',
                          marginLeft: '10px',
                        }}
                      >
                        Change
                      </div>
                    </span>
                  </Col>
                </Row>
              </div>
            </div>

            {/* <Row className="mb-2">
              <Col>
                <h4 className="p-2" style={{ background: "rgba(0,0,0,.05)" }}>Payment Details</h4>
                <Row xs>
                  <Col xs>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Credit Details</th>
                          <th>Expiry Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>************1234</td>
                          <td>22 Sep 2022</td>
                          <td>
                            <ButtonToolbar>
                              <ButtonGroup>
                                <Button onClick={() => setModalShow4(true)} className="c-btn c-btn-gradient">
                                  <span className="c-btn-text">Edit</span>
                                </Button>
                              </ButtonGroup>
                            </ButtonToolbar>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
            </Row> */}
          </Col>
        </Row>

        <MyVerticallyCenteredModal
          size='lg'
          modalTitle='Select Plan'
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        >
          <div className='col-sm-12'>
            {(planDetails.plan.plan_name.toLowerCase() === 'free' ||
              !isLoggedIn()) && (
              <div className='billing-option'>
                <label className='switch'>
                  <input
                    type='checkbox'
                    defaultChecked={true}
                    checked={isYearly}
                    onChange={(ev) => {
                      setIsYearly(ev.target.checked);
                    }}
                    className='js-billing-type'
                  />
                  <label className='bill--monthly'>Bill Monthly</label>
                  <span className='slider round'></span>
                  <label className='bill--yearly'>Bill Yearly</label>
                </label>

                <span className='d-flex flex-column save-tag'>
                  <span>Save 16%</span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <polyline points='9 10 4 15 9 20'></polyline>
                    <path d='M20 4v7a4 4 0 0 1-4 4H4'></path>
                  </svg>
                </span>
              </div>
            )}

            <div className='pricing-plans-wrapper'>
              <div className='pricing-plan'>
                {plans.map((x) => (
                  <SinglePlan
                    planStatus={planStatus}
                    onCancelPlan={intializePlanCancellation}
                    onSelect={intializePaymentSession}
                    selectedPlan={planDetails}
                    id={x.id}
                    key={x.id}
                    planKey={x.name.toLowerCase()}
                    billIsYearly={isYearly}
                  />
                ))}
              </div>
            </div>
          </div>
        </MyVerticallyCenteredModal>

        <MyVerticallyCenteredModal
          modalTitle='Change Password'
          primaryBtn={{ text: 'Submit', handler: submitChangePassword }}
          show={modalShow3}
          onHide={() => setModalShow3(false)}
        >
          <Form>
            <Form.Group className='mb-3' controlId='formPassword'>
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter Password'
                onChange={(ev) => {
                  setChangePasswordRequest({
                    ...changePasswordRequest,
                    old_password: ev.target.value,
                  });
                  checkValidity('old_password', ev.target.value);
                }}
              />
              {isTouched &&
                errorMessage.old_password &&
                !errorMessage.old_password.success && (
                  <label className='error'>
                    {errorMessage.old_password.errorMessage ||
                      'This field is required'}
                  </label>
                )}
            </Form.Group>

            <Form.Group className='mb-3' controlId='formPassword'>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter New Password'
                onChange={(ev) => {
                  setChangePasswordRequest({
                    ...changePasswordRequest,
                    new_password: ev.target.value,
                  });
                  checkValidity('new_password', ev.target.value);
                }}
              />
              {isTouched &&
                errorMessage.new_password &&
                !errorMessage.new_password.success && (
                  <label className='error'>
                    {errorMessage.new_password.errorMessage ||
                      'This field is required'}
                  </label>
                )}
            </Form.Group>

            <Form.Group className='mb-3' controlId='formPassword'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter Confirm Password'
                onChange={(ev) => {
                  setChangePasswordRequest({
                    ...changePasswordRequest,
                    confirm_password: ev.target.value,
                  });
                  checkValidity('confirm_password', ev.target.value);
                }}
              />
              {isTouched &&
                errorMessage.confirm_password &&
                !errorMessage.confirm_password.success && (
                  <label className='error'>
                    {errorMessage.confirm_password.errorMessage ||
                      'This field is required'}
                  </label>
                )}
            </Form.Group>
          </Form>
        </MyVerticallyCenteredModal>

        <MyVerticallyCenteredModal
          modalTitle='Change Email'
          primaryBtn={{ text: 'Submit', handler: submitChangeDetails }}
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        >
          <Form>
            <Form.Group className='mb-3' controlId='formEmail'>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                onChange={(ev) => {
                  setChangeEmailRequest({
                    ...changeEmailRequest,
                    email: ev.target.value,
                  });
                  checkValidity('email', ev.target.value);
                }}
              />
              {isTouched &&
                errorMessage.email &&
                !errorMessage.email.success && (
                  <label className='error'>
                    {errorMessage.email.errorMessage ||
                      'This field is required'}
                  </label>
                )}
            </Form.Group>

            <Form.Group className='mb-3' controlId='formConfirmEmail'>
              <Form.Label>Confirm Email address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter confirmation email'
                onChange={(ev) => {
                  setChangeEmailRequest({
                    ...changeEmailRequest,
                    confirm_email: ev.target.value,
                  });
                  checkValidity('confirm_email', ev.target.value);
                }}
              />
              {isTouched &&
                errorMessage.confirm_email &&
                !errorMessage.confirm_email.success && (
                  <label className='error'>
                    {errorMessage.confirm_email.errorMessage ||
                      'This field is required'}
                  </label>
                )}
            </Form.Group>
          </Form>
        </MyVerticallyCenteredModal>
      </Container>
    </>
  );
}

export default AccountDetails;
