import { useState, useEffect } from 'react';
import SinglePlan from './singlePlan';
import fallbackPlan from '../../assets/data/fallback-plan';
import tickImg from '../../assets/images/icons/tick.png';
import crossImg from '../../assets/images/icons/cross.png';
import {
  isLoggedIn,
  getUserInfo,
  prepareErrorMessage,
} from '../../utils/Helper';
import { api } from '../../interceptor/interceptor';
import { GeneralLogic } from '../../interceptor/generalLogic';
import toast from 'react-hot-toast';

function Plans() {
  const { appPricingPlans } = GeneralLogic();
  const [planDetails, setPlanDetails] = useState(fallbackPlan);
  const [isYearly, setIsYearly] = useState(true);
  const [planStatus, setPlanStatus] = useState('active');

  const plans = appPricingPlans;

  let objects_key = [];
  if (plans.length > 0 && plans[0].plan_details) {
    objects_key = Object.keys(plans[0].plan_details);
  }
  const humanize = (str) => {
    const a = str.replace(/#|_/g, ' ');
    return a;
  };

  const settingsplans = (str, key) => {
    if (key === 'allowed_storage' && str === -1) {
      return 'Unlimited';
    } else if (key === 'allowed_storage') {
      return str;
    } else {
      if (str === 1) {
        return <img src={tickImg} alt='tickimg' />;
      } else if (str === 0) {
        return <img src={crossImg} alt='crossimg' />;
      } else if (str === -1) {
        return 'Unlimited';
      } else {
        return str;
      }
    }
  };

  const fetchPlanDetails = async () => {
    const userInfo = getUserInfo();
    if (!userInfo) return;
    const requestBody = { user_id: userInfo.user_id };
    await api.headers(true).then(async () => {
      await api
        .method('post', 'membership/getUserPlan', requestBody)
        .then((res) => {
          let planReponse = res.data.data.user_plan[0];
          if (planReponse) {
            planReponse.plan.plan_details = JSON.parse(
              planReponse.plan.plan_details
            );
            setPlanDetails(planReponse);
            if (res.data.data.Subscription) {
              setPlanStatus(res.data.data.Subscription.toLowerCase());
            }
            const isYearlyPlan = planReponse.subscription_period === 'yearly';
            setIsYearly(isYearlyPlan);
          }
        })
        .catch((err) => {
          const message = err.response
            ? err.response.data
            : "Request couldn't be completed!";
          prepareErrorMessage(message);
        });
    });
  };

  const intializePaymentSession = async (planId, isEnterprise = false) => {
    // Link to contact form if enterprise
    if (isEnterprise) {
      window.location.href = 'https://cloudexpo.dev/#contact';
      return;
    }

    const confirmation = window.confirm(
      'Are you sure you want to change your plan?'
    );

    if (confirmation) {
      const interval = isYearly ? 'year' : 'month';
      const requestBody = { interval, plan_id: planId };
      await api.headers(false, true).then(async () => {
        await api
          .method('post', 'membership/createSession', requestBody)
          .then((res) => {
            const data = res.data.data;
            const redirectURL = data[0].url;
            const sessionId = data[0].id;
            localStorage.setItem(
              'PAYMENT-SESSION',
              JSON.stringify({ session_id: sessionId, plan_id: planId })
            );
            toast.success(
              'Payment has been initialized, redirecting you in 3..2..1!'
            );

            setTimeout(() => {
              window.location.href = redirectURL;
            }, 3000);
          });
      });
    }
  };

  const intializePlanCancellation = async () => {
    const confirmation = window.confirm(
      'Are you sure you want to cancel your plan?'
    );
    if (confirmation) {
      const params = { membership_history_id: planDetails.id };
      await api.headers(false, true).then(async () => {
        await api
          .method('post', 'membership/cancelSubscription', null, { params })
          .then((res) => {
            toast.success('Plan has been successfully cancelled!');
            fetchPlanDetails();
          })
          .catch((err) => {
            prepareErrorMessage(err.response.data);
          });
      });
    }
  };

  useEffect(() => {
    if (isLoggedIn()) {
      fetchPlanDetails();
    }
  }, []);

  return (
    <section id='pricing' className='section-pricing section-slide js-section'>
      <div className='container'>
        <div className='ce-intro'>
          <div className='row align-items-center'>
            <div className='col-sm-12'>
              <div data-content-title='pricing' className='ce-intro-content'>
                <span className='tagline two-lined slide-up'>
                  Simple, Transparent Pricing
                </span>
                <h2 style={{ transitionDelay: '0.6s' }} className='slide-up'>
                  <span className='font-thin'>Our Plans</span> For Your
                  <span className='blue-highlight'> Strategies</span>
                </h2>

                <p style={{ transitionDelay: '0.9s' }} className='slide-up'>
                  Try out <span className='blue-highlight'>Cloud</span>Expo for
                  free and create a virtual tour your audience will love today.
                </p>
              </div>
            </div>

            <div className='col-sm-12'>
              {(planDetails.plan.plan_name.toLowerCase() === 'free' ||
                !isLoggedIn()) && (
                <div className='billing-option'>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      checked={isYearly}
                      onChange={(ev) => {
                        setIsYearly(ev.target.checked);
                      }}
                      className='js-billing-type'
                    />
                    <label className='bill--monthly'>Bill Monthly</label>
                    <span className='slider round'></span>
                    <label className='bill--yearly'>Bill Yearly</label>
                  </label>

                  <span className='d-flex flex-column save-tag'>
                    <span>Save 16%</span>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <polyline points='9 10 4 15 9 20'></polyline>
                      <path d='M20 4v7a4 4 0 0 1-4 4H4'></path>
                    </svg>
                  </span>
                </div>
              )}

              {}
              <div className='pricing-plans-wrapper'>
                <div className='pricing-plan'>
                  {plans.map((x) => (
                    <SinglePlan
                      planStatus={planStatus}
                      onCancelPlan={intializePlanCancellation}
                      onSelect={intializePaymentSession}
                      selectedPlan={planDetails}
                      id={x.id}
                      planKey={x.name.toLowerCase()}
                      billIsYearly={isYearly}
                      key={`plan-${x.id}`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='table--wrapper'>
          <div className='table-rows header'>
            <div className='title'>
              <p>Title</p>
            </div>
            {plans.map((p, index) => {
              return (
                <div key={index} className='table--info'>
                  <p>{p.name}</p>
                </div>
              );
            })}
          </div>
          {objects_key.map((x, index) => {
            return (
              <div key={index} className='table-rows'>
                <div className='title'>
                  <p>{humanize(x)}</p>
                </div>

                {plans.map((a, index) => {
                  return (
                    <div key={index} className='table--info'>
                      <p>{settingsplans(a.plan_details[x], x)}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Plans;
