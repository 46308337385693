const INITIAL_STATE = {
  isLoggedIn: false,
  info: {},
};

function newState(state, user = {}, isLoggedIn = false) {
  return { ...state, user, isLoggedIn };
}

export function AuthReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case `LOGGED_IN`:
      return newState(state, action.data, true);
    default:
      return newState(state);
  }
}
