import React from 'react';
import AccountDetails from '../components/AccountDetails/accountDetails';
import Header from '../components/Header/header';
import { isLoggedIn } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';

const AccountDetailsPage = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='loggedin-container page--account-details ce-nav-padding'>
      <Header forcedFixed={true} />

      <AccountDetails />
    </div>
  );
};

export default AccountDetailsPage;
