import React from "react";
import PaymentSuccess from "../components/PaymentResult/paymentSuccess";
import PaymentFailure from "../components/PaymentResult/paymentFailure";
import Header from '../components/Header/header';

const PaymentResultsPage = (props) => {
  
  return (
    <div className="loggedin-container page-payment-result">

      <Header forcedFixed={true} />

      {props.location.pathname.includes('payment/success') && (
        <PaymentSuccess />
      )}

      {props.location.pathname.includes('payment/failure') && (
        <PaymentFailure />
      )}

    </div>
  )
}

export default PaymentResultsPage

