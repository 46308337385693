import { regexPatterns } from './Constants';
import moment from 'moment';
import toast from 'react-hot-toast';

export const getSessionFromLocalStorage = () => {
  const session = JSON.parse(localStorage.getItem('user-session'));
  if (session && session.token) {
    return true;
  }
  return false;
};

export const validity = ({ required = true, regexPattern = null }, value) => {
  let response = {
    success: false,
    errorMessage: 'Field is not valid',
  };
  if (required && !value) {
    response.success = false;
    response.errorMessage = 'This field is required';
    return response;
  }

  if (
    regexPattern &&
    regexPatterns[regexPattern] &&
    !regexPatterns[regexPattern].test(value)
  ) {
    response.success = false;
    switch (regexPattern) {
      case 'email':
        response.errorMessage = 'Email field should be valid e.g. abc@xyz.co';
        break;
      case 'password':
        response.errorMessage =
          'Password should atleast contain 1 uppercase, 1 number and 1 special character';
        break;
      default:
        break;
    }
    return response;
  }

  // If it reaches here then all is well
  response.success = true;
  response.errorMessage = '';

  return response;
};

export const isLoggedIn = () => {
  return localStorage.getItem('session') ? true : false;
};

export const compareTime = (fromTimeStamp, toTimeStamp, format = 'minute') => {
  let response = toTimeStamp - fromTimeStamp;
  switch (format) {
    case 'minute':
      response = response / 60000;
      break;
    default:
      break;
  }
  return response;
};

export const generateRandomCharacters = (length, scope = 'all') => {
  let result = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  switch (scope) {
    case 'number':
      characters = '0123456789';
      break;
    default:
      break;
  }

  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getUserInfo = () => {
  let result = {};
  try {
    if (isLoggedIn) {
      result = JSON.parse(localStorage.getItem('session'));
    }
    return result;
  } catch (error) {
    return result;
  }
};

export const formatDate = (inputDate, format = 'DD-MM-YYYY') => {
  let formattedDate = '01-01-0001';
  if (inputDate) {
    formattedDate = moment(inputDate).format(format);
  }
  return formattedDate;
};

export const prepareErrorMessage = (errorResponse) => {
  let message = errorResponse?.message || "Request couldn't be completed";
  let isMultiLine = false;
  let errorListString = '';

  // Multi Error Messages
  /* if (errorResponse?.errors && Object.keys(errorResponse?.errors).length > 0) { // Collection of errors
    errorListString = "";

    Object.keys(errorResponse.errors).forEach((val) => {
      errorListString += `<li>${errorResponse.errors[val][0]}</li>`
    });
    isMultiLine = true;
  } */

  if (errorResponse?.errors && typeof errorResponse?.errors === 'object') {
    let key = Object.keys(errorResponse?.errors)[0];
    message = key
      ? errorResponse.errors[key][0]
      : "Request couldn't be completed";
  } else if (
    errorResponse?.errors &&
    typeof errorResponse?.errors === 'string'
  ) {
    message = errorResponse?.errors;
  }

  if (isMultiLine) {
    toast.custom((t) => (
      <div className='ce-error-wrapper'>
        <h4>Errors: </h4>
        <ul dangerouslySetInnerHTML={{ __html: errorListString }}></ul>
      </div>
    ));
  } else {
    toast.error(message);
  }
};

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i < 0) i = 0;
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};
