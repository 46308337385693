import React from 'react';
import Oculus from '../../assets/images/partner_companies/oculus.png';
import Huawei from '../../assets/images/partner_companies/huawei.png';
import Microsoft from '../../assets/images/partner_companies/microsoft.png';
import Nvidia from '../../assets/images/partner_companies/nvidia.png';
import Kandar from '../../assets/images/partner_companies/kandar.png';
import './partnerCompanies.css';
import '../../assets/css/style.min.css';

function PartnerCompanies() {
  return (
    <React.Fragment>
      <section className='companies-section js-section section-slide'>
        <div className='container companies-section-container'>
          <div className='ce-intro-content'>
            <h2 style={{ transitionDelay: '0.6s' }} className='slide-up'>
              <span className='font-thin'>Strategic</span>{' '}
              <span className='blue-highlight'>Partners</span>
            </h2>
          </div>
          <div>
            <div
              style={{ transitionDelay: '0.9s' }}
              className='horiz-list slide-up'
            >
              <img src={Oculus} className='companies-logo' alt='oculus' />
              <img src={Huawei} className='companies-logo' alt='huawei' />
              <img src={Microsoft} className='companies-logo' alt='microsoft' />
              <img src={Nvidia} className='companies-logo' alt='nvidia' />
              <a
                href='https://www.kandaovr.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={Kandar} className='companies-logo' alt='kandar' />
              </a>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default PartnerCompanies;
