function CompanyInfo() {
    return (
        <section className="company-info js-section section-slide">
        <div className="container">
          <div className="ce-intro-content">
            <h2 className="slide-up">
              <span className="font-thin">About</span> <span className="blue-highlight"> Hiverlab</span>
            </h2>

            <h4 style={{ transitionDelay: "0.3s" }} className="slide-up">
              Immersive Technology Company from Singapore
            </h4>

            <p style={{ transitionDelay: "0.6s" }} className="slide-up">
              Hiverlab is an award winning technology company offering digital
              transformation solutions such as Augmented Reality, Virtual
              Reality, Mixed Reality, Cloud Computing, System Integrations,
              and Data Visualisation.
            </p>

            <p style={{ transitionDelay: "0.6s" }} className="slide-up">
              Founded in 2014, Hiverlab has grown to be one of Singapore’s
              most prominent digital transformation enabler. Having worked
              with over 150 clients across 14 industries and 10 countries,
              including fortune 500 companies and government agencies.
            </p>

            <p style={{ transitionDelay: "0.6s" }}className="slide-up">
              With a multi-disciplinary team behind many pioneering projects,
              we have established long-term strategic partnerships with
              important industry players such as Samsung, Huawei, AliCloud,
              NVIDIA, Autodesk, Seagate, DB Schenker and more. Appearing on
              mainstream media, such as CNBC, The Straits Times, Channel News
              Asia, Mediacorp, Sina, and more.
            </p>

            <p style={{ transitionDelay: "0.6s" }} className="slide-up">
              If you are searching for a trustworthy and reputed immersive
              technology developer, look no further than Hiverlab.
            </p>

            <div className="row mt-5">
              <div className="col-sm-4">
                <h4 style={{ transitionDelay: "0.9s" }} className="slide-up">
                  Immersive Virtual Tours
                </h4>
                <p style={{ transitionDelay: "1.2s" }} className="slide-up">
                  Create immersive and engaging virtual tours with our
                  self-serve virtual tour building platform - CloudExpo.
                </p>
              </div>
              <div className="col-sm-4">
                <h4 style={{ transitionDelay: "0.9s" }} className="slide-up">
                  Interactive AR/VR Experience
                </h4>
                <p style={{ transitionDelay: "1.2s" }} className="slide-up">
                  Craft an interactive immersive application for your
                  audience. Engage them in a holistic experience.
                </p>
              </div>
              <div className="col-sm-4">
                <h4 style={{ transitionDelay: "0.9s" }} className="slide-up">
                  Digital Twin
                </h4>
                <p style={{ transitionDelay: "1.2s" }} className="slide-up">
                  Leverage on the latest advancement in tech with Hiverlab’s
                  Digital Twin solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default CompanyInfo;