import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/header';

function NotFound() {
  return (
    <div className='page-container'>
      <Header />
      <div className='notfound-div'>Page Not Found</div>
      <Footer />
    </div>
  );
}

export default NotFound;
