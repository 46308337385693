// import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import HeroDemo from '../../../src/assets/images/hero-demo.gif';

function BannerSection() {
  return (
    <>
      <section
        id='home'
        className='section-banner section-slide js-section first-fold new-home'
      >
        <div className='container'>
          <div className='banner-wrapper'>
            <div className='banner-content'>
              <h2 style={{ transitionDelay: '0.6s' }} className='slide-up'>
                Virtual <br />
                <span>Space Creator </span>
                <br />
                for enterprise
              </h2>
              <p style={{ transitionDelay: '0.9s' }} className='slide-up'>
                Experience the future of virtual engagement, the web-native
                platform for learning, visualization, and presentation.
              </p>
              <Link
                id='banner-link'
                style={{ transitionDelay: '1.2s' }}
                to='/register/free'
                className='c-btn c-btn-gradient slide-up'
              >
                <span className='c-btn-text'>Sign Up Now</span>
              </Link>
              <a
                style={{ transitionDelay: '1.2s' }}
                href='https://cloudexpo.hiverlab.com/sample/'
                target='_blank'
                className='c-btn c-btn-gradient slide-up'
                rel='noreferrer'
              >
                <span className='c-btn-text'>Try our Editor</span>
              </a>
            </div>
            <div className='bannerImg'>
              <img src={HeroDemo} alt='Hero Demo' />
              {/* <Lottie options={defaultOptions}
                            height={400}
                            width={400} /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BannerSection;
