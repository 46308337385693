/* eslint-disable default-case */
import { useReducer, useState } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import './login.css';

import { useNavigate } from 'react-router-dom';

import UILoader from '../General/UILoader';
import { validity, prepareErrorMessage } from '../../utils/Helper';

import { api } from '../../interceptor/interceptor';
import { AuthReducer } from '../../reducers/reducer_auth';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

function Login() {
  // eslint-disable-next-line no-unused-vars
  const [_, dispatch] = useReducer(AuthReducer);
  const navigate = useNavigate();
  const [isTouched, setIsTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState({});

  const formSubmit = async (e) => {
    e.preventDefault();
    setIsTouched(true);

    if (email && password && !errorMessage.email && !errorMessage.password) {
      let requestBody = { email, password };
      setIsLoading(true);
      await api.headers(true).then(async () => {
        await api
          .method('post', 'login', requestBody)
          .then((res) => {
            // console.log(res);
            const { data, status } = res || {};
            if (status === 200) {
              localStorage.setItem('session', JSON.stringify(data.data));
              dispatch(data.data, { type: 'LOGGED_IN' });
              navigate('/account');
              toast.success('Logged in successfully!');
            }
          })
          .catch((err) => {
            console.log(err);
            prepareErrorMessage(err.response.data);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
  };

  const checkValidity = (key, value) => {
    let fieldsValidity;
    switch (key) {
      case 'email':
        fieldsValidity = validity(
          { required: true, regexPattern: 'email' },
          value
        );
        break;
      case 'password':
        fieldsValidity = validity({ required: true }, value);
        break;
    }
    if (!fieldsValidity.success) {
      let errorMessageObject = {};
      errorMessageObject[key] = fieldsValidity;
      setErrorMessage({
        ...errorMessage,
        ...errorMessageObject,
      });
    } else {
      delete errorMessage[key];
    }
  };

  return (
    <Container>
      <Row className='d-flex align-items-center justify-content-center customHeight'>
        <Col xs lg='6'>
          {/* <Image src={logoImage} alt="logo" /> */}

          <div className='mb-4'>
            <span className='tagline '>A real experience which begins now</span>
            <h2 style={{ transitionDelay: '0.6s' }} className=''>
              <span className='font-thin'>Login to </span>
              <span className='blue-highlight'>CloudExpo</span>
            </h2>
          </div>

          <Form onSubmit={formSubmit}>
            <Form.Group className='mb-3' controlId='formEmail'>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                onChange={(ev) => {
                  setEmail(ev.target.value);
                  checkValidity('email', ev.target.value);
                }}
              />
              {isTouched &&
                errorMessage.email &&
                !errorMessage.email.success && (
                  <label className='error'>
                    {errorMessage.email.errorMessage}
                  </label>
                )}
            </Form.Group>

            <Form.Group className='mb-3' controlId='formPassword'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter Password'
                onChange={(ev) => {
                  setPassword(ev.target.value);
                  checkValidity('password', ev.target.value);
                }}
              />
              {isTouched &&
                errorMessage.password &&
                !errorMessage.password.success && (
                  <label className='error'>
                    {errorMessage.password.errorMessage}
                  </label>
                )}
            </Form.Group>

            <div className='login--btn d-flex justify-content-end'>
              <button
                className={`c-btn c-btn-gradient ${
                  isLoading ? 'is--loading' : ''
                }`}
              >
                <UILoader />
                <span className='c-btn-text'>Login</span>
              </button>
              <div className='signup-content'>
                <p>
                  Don't have an account yet? <Link to='/register'>Sign Up</Link>
                </p>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
