import React from 'react';

const Panorama = () => {
  return (
    <>
      <div className='banner-artwork'>
        <iframe
          title='cloudexpo editor sample version'
          src='https://cloudexpo.hiverlab.com/?lobby'
          style={{ width: '100%', height: '100vh' }}
          scrolling='yes'
          frameBorder='0'
        ></iframe>
      </div>
    </>
  );
};

export default Panorama;
