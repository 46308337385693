import LiveTour from '../../assets/images/icons/Live-Tour.png';
import Collaboration from '../../assets/images/icons/Collaboration.png';
import Pointer3 from '../../assets/images/icons/pointer3.png';
import FlexibleDeployment from '../../assets/images/icons/Flexible-Deployment.png';
import Pointer4 from '../../assets/images/icons/pointer4.png';
import Security from '../../assets/images/icons/security.png';

function Features() {
    return (
        <section
          id="features"
          className="section-features section-slide js-section"
        >
          <div className="container">
            <div
              data-content-title="Benefits"
              className="section-title section-title-centered"
            >
              <span className="tagline two-lined slide-up">features</span>
              <h2 style={{ transitionDelay: "0.6s" }} className="slide-up">
                <span className="font-thin">Features & </span><span className="blue-highlight">benefits</span>
              </h2>

              <p style={{ transitionDelay: "0.9s" }} className="slide-up">
                Create self-serve virtual tours, embed information, content, and
                interactive functions.
              </p>
            </div>

            <div className="feature-boxes-wrapper">
              <div className="row no-gutters justify-content-center">
                <div
                  style={{ transitionDelay: "0.3s" }}
                  className="feature-column slide-up"
                >
                  <div data-content-title="01" className="content-box">
                    <img
                      className="features-image"
                      src={LiveTour}
                      alt="Virtual Venue"
                    />

                    <h4>Live Tours and Multi User Interaction</h4>

                    <hr />

                    <p>
                      Open up endless possibilities with&nbsp;
                      <span className="blue-highlight">Cloud</span>Expo's multi user
                      feature. Host a live tour and interact with your audience
                      via text, audio and video.
                    </p>
                  </div>
                </div>
                <div
                  style={{ transitionDelay: "0.6s" }}
                  className="feature-column slide-up"
                >
                  <div data-content-title="02" className="content-box">
                    <img
                      className="features-image"
                      src={Collaboration}
                      alt="Interactive Experience"
                    />

                    <h4>Collaborate through the cloud</h4>

                    <hr />

                    <p>
                      Collaborate on&nbsp;
                      <span className="blue-highlight">Cloud</span>Expo projects
                      with your team through our workgroup feature. Ensure
                      creative assets are easily accessible by everyone in your
                      team.
                    </p>
                  </div>
                </div>
                <div
                  style={{ transitionDelay: "0.9s" }}
                  className="feature-column slide-up"
                >
                  <div data-content-title="03" className="content-box">
                    <img
                      className="features-image"
                      id="pointer3"
                      src={Pointer3}
                      alt="Embedded or URL"
                    />

                    <h4>Embed all types of elements</h4>

                    <hr />

                    <p>
                      Embed a wide array of elements within your virtual tour,
                      create an interactive experience that your audience will
                      love. <span className="blue-highlight">Cloud</span>Expo
                      provides a range of embeddable elements for you to use.
                    </p>
                  </div>
                </div>
                <div
                  style={{ transitionDelay: "1.2s" }}
                  className="feature-column slide-up"
                >
                  <div data-content-title="04" className="content-box">
                    <img
                      className="features-image"
                      src={FlexibleDeployment}
                      alt="Embedded or URL"
                    />

                    <h4>Flexible deployment, control access</h4>

                    <hr />

                    <p>
                      Publish your creation anywhere.&nbsp;
                      <span className="blue-highlight">Cloud</span>Expo allows you
                      to publish your virtual venue via URL or embedded HTML.
                      With privacy options such as OTP and email whitelist,
                      control who can view your virtual tour.
                    </p>
                  </div>
                </div>
                <div
                  style={{ transitionDelay: "1.5s" }}
                  className="feature-column slide-up"
                >
                  <div data-content-title="05" className="content-box">
                    <img
                      className="features-image"
                      src={Pointer4}
                      alt="Built-in Analytics"
                    />

                    <h4>Understand your visitors with analytics</h4>

                    <hr />

                    <p>
                      Understand your visitors with our in-built analytics
                      dashboard. Learn which scene or elements your visitors are
                      interested in.
                    </p>
                  </div>
                </div>
                <div
                  style={{ transitionDelay: "1.5s" }}
                  className="feature-column slide-up"
                >
                  <div data-content-title="06" className="content-box">
                    <img
                      className="features-image"
                      src={Security}
                      alt="Enterprise Grade Data Protection"
                    />

                    <h4>Enterprise Grade Data Protection</h4>

                    <hr />

                    <p>
                      Vulnerability Assessment and Penetration Testing Certified by CREST International Accredited company. Keep your data secure with us. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
}

export default Features;