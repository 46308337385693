const fallbackPlan = {
  start_date: "01-01-0001",
  asset_size: 0,
  end_date: null,
  project_created: 0,
  plan: {
    plan_name: "N/A",
    plan_details: {
      project_creation: 0,
      allowed_projects: 0,
      allowed_storage: 0,
      available_storage: 0,
    }
  }
}

export default fallbackPlan;