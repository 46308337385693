import { api } from './interceptor';
import axios from 'axios';
import pricingPlans from '../assets/data/pricing-plans';
import { useState } from 'react';

let { plans } = pricingPlans;

export const GeneralLogic = () => {
  const [planConfigured, setPlansConfigured] = useState(false);
  const [appPricingPlans, setPricingPlans] = useState(plans);
  const [sessionLocation, setSessionLocation] = useState(null);
  const [sgdCurrencyRate, setSgdCurrencyRate] = useState(null);

  const initPlanConfiguration = async () => {
    if (!planConfigured) {
      await api
        .method('get', 'membership/getMembershipPlans')
        .then((res) => {
          const data = res.data.data;
          data.forEach((element, index) => {
            plans[index].id = element.id;
            plans[index].name = element.plan_name;
            plans[index].plan_details = JSON.parse(element.plan_details);
            plans[index].usd_prices = {
              monthly: element.monthly_cost,
              yearly: element.yearly_cost,
            };
          });

          setPricingPlans(plans);
          setPlansConfigured(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchSessionLocation = async () => {
    if (!sessionLocation) {
      await axios
        .get('https://geolocation-db.com/json/geoip.php')
        .then((res) => {
          const data = res.data;
          setSessionLocation(data.country_name);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchCurrencyRates = async () => {
    try {
      if (!sgdCurrencyRate) {
        await axios
          .get(
            'https://free.currencyconverterapi.com/api/v5/convert?q=USD_SGD&compact=y&apiKey=c9285599acfec3788be6'
          )
          .then((res) => {
            const data = res.data;
            setSgdCurrencyRate(data.USD_SGD.val);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    appPricingPlans,
    sessionLocation,
    sgdCurrencyRate,
    initPlanConfiguration,
    fetchSessionLocation,
    fetchCurrencyRates,
  };
};
