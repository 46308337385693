import { Row, Col, Container, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import SinglePlan from '../Plans/singlePlan';
import UILoader from '../General/UILoader';
import { GeneralLogic } from '../../interceptor/generalLogic';

import { useState } from 'react';

import { api } from '../../interceptor/interceptor';
import {
  generateRandomCharacters,
  prepareErrorMessage,
  validity,
} from '../../utils/Helper';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import './registration.css';

function Registration(props) {
  const { appPricingPlans } = GeneralLogic();

  const navigate = useNavigate();
  let { planType, billType } = useParams();

  let planNameProp = planType || 'free';
  let billTypeProp = billType || 'yearly';
  const isYearly = billTypeProp === 'monthly' ? false : true;

  const currentPlan = appPricingPlans.find(
    (x) => x.name.toLowerCase() === planNameProp.toLowerCase()
  );

  // Register Logic
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isPromoLoading, setIsPromoLoading] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [requestBody, setRequestBody] = useState({
    device: navigator.userAgent || 'website',
    verified: true,
  });

  const [errorMessage, setErrorMessage] = useState({});
  const [SignupDisabled, setSignupDisabled] = useState(false);
  const [verified, setVerified] = useState(false);
  const requiredFields = ['first_name', 'last_name', 'password', 'email'];

  const formSubmit = async (e) => {
    e.preventDefault();
    setIsTouched(true);

    let isValid = false;
    requiredFields.forEach((key) => {
      if (requestBody[key]) {
        isValid = true;
      } else {
        isValid = false;
        return true;
      }
    });

    const utmTags = localStorage.getItem('utm_tags');
    if (utmTags !== null) {
      requestBody.referral_tags = utmTags;
    }

    const errors = Object.keys(errorMessage);

    if (errors.length === 0 && isValid) {
      setIsLoading(true);
      await api.headers(true).then(async () => {
        await api
          .method('post', 'user', requestBody)
          .then((res) => {
            if (res.status === 200) {
              let data = res.data.data;
              localStorage.setItem('session', JSON.stringify(data));
              localStorage.removeItem('utm_tags');
              launchMailchimpCampaign({
                event: 'FirstLogin',
                email: requestBody.email,
              });
              if (currentPlan.key === 'free' || requestBody.promoCode) {
                navigate('/account');
              } else {
                intializePaymentSession();
              }
              toast.success(res.data.message);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            prepareErrorMessage(err.response.data);
            setIsLoading(false);
          });
      });
    }
  };

  // Promo Verfication Handler
  // eslint-disable-next-line no-unused-vars
  const verifyPromo = async (e) => {
    e.preventDefault();
    setIsTouched(true);

    const errors = Object.keys(errorMessage);

    if (errors.length === 0) {
      setIsPromoLoading(true);
      let promoRequest = { code: requestBody.promoCode };
      await api.headers(true).then(async () => {
        await api
          .method('post', 'promocode/checkPromoCode', promoRequest)
          .then((res) => {
            if (res.status === 200) {
              setVerified(true);
              setSignupDisabled(false);
            }
            setIsPromoLoading(false);
          })
          .catch((err) => {
            setVerified(null);
            prepareErrorMessage(err.response.data);
            setIsPromoLoading(false);
          });
      });
    }
  };

  const checkValidity = (key, value) => {
    let fieldsValidity;
    switch (key) {
      case 'email':
        fieldsValidity = validity(
          { required: true, regexPattern: 'email' },
          value
        );
        break;
      case 'password':
        fieldsValidity = validity(
          { required: true, regexPattern: 'password' },
          value
        );
        break;
      default:
        fieldsValidity = validity({ required: true }, value);
        break;
    }
    if (!fieldsValidity.success) {
      let errorMessageObject = {};
      errorMessageObject[key] = fieldsValidity;
      setErrorMessage({
        ...errorMessage,
        ...errorMessageObject,
      });
    } else {
      delete errorMessage[key];
    }
  };

  // Singnup enable/disable handler
  // eslint-disable-next-line no-unused-vars
  const handleChange = (ev) => {
    if (ev.target.value.length > 1 && !verified) {
      setSignupDisabled(true);
    } else if (verified) {
      setSignupDisabled(false);
    } else {
      setSignupDisabled(false);
    }
    setRequestBody({ ...requestBody, promoCode: ev.target.value });
  };

  const updateNames = () => {
    if (requestBody.first_name && requestBody.last_name) {
      setRequestBody({
        ...requestBody,
        full_name: `${requestBody.first_name} ${requestBody.last_name}`,
        nick_name: `${requestBody.first_name}${generateRandomCharacters(
          3,
          'number'
        )}`,
      });
    }
  };

  const intializePaymentSession = async () => {
    const interval = billTypeProp === 'yearly' ? 'year' : 'month';
    const requestBody = { interval, plan_id: currentPlan.id };
    await api.headers(false, true).then(async () => {
      await api
        .method('post', 'membership/createSession', requestBody)
        .then((res) => {
          const data = res.data.data;
          const redirectURL = data[0].url;
          const sessionId = data[0].id;
          localStorage.setItem(
            'PAYMENT-SESSION',
            JSON.stringify({ session_id: sessionId, plan_id: currentPlan.id })
          );
          toast.success(
            'Payment has been initialized, redirecting you in 3..2..1!'
          );

          setTimeout(() => {
            window.location.href = redirectURL;
          }, 3000);
        });
    });
  };

  const launchMailchimpCampaign = async (body) => {
    await api.headers(false, true).then(async () => {
      await api
        .method('post', 'sendCampaign', body, {
          baseURL: process.env.REACT_APP_CLOUDEXPO_API_BASE_URL,
        })
        .then(() => {});
    });
  };

  return (
    <Container>
      <Row className='d-flex align-items-center justify-content-center customHeight'>
        <Col xs lg='6'>
          <br />
          <br />
          <div className='mb-4'>
            <span className='tagline '>A real experience which begins now</span>
            <h2 style={{ transitionDelay: '0.6s' }} className=''>
              <span className='font-thin'>
                Register yourself
                <br /> with{' '}
              </span>

              <span className='blue-highlight'>CloudExpo</span>
            </h2>
          </div>

          <Form onSubmit={formSubmit}>
            <Row>
              <Col>
                <Form.Group className='mb-3' controlId='formFirstName'>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter First Name'
                    onChange={(ev) => {
                      setRequestBody({
                        ...requestBody,
                        first_name: ev.target.value,
                      });
                      checkValidity('first_name', ev.target.value);
                    }}
                    onBlur={updateNames}
                  />
                  {isTouched &&
                    errorMessage.first_name &&
                    !errorMessage.first_name.success && (
                      <label className='error'>
                        {errorMessage.first_name.errorMessage ||
                          'This field is required'}
                      </label>
                    )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3' controlId='formLastName'>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Last Name'
                    onChange={(ev) => {
                      setRequestBody({
                        ...requestBody,
                        last_name: ev.target.value,
                      });
                      checkValidity('last_name', ev.target.value);
                    }}
                    onBlur={updateNames}
                  />
                  {isTouched &&
                    errorMessage.last_name &&
                    !errorMessage.last_name.success && (
                      <label className='error'>
                        {errorMessage.last_name.errorMessage ||
                          'This field is required'}
                      </label>
                    )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-3' controlId='formEmail'>
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter email'
                    onChange={(ev) => {
                      setRequestBody({
                        ...requestBody,
                        email: ev.target.value,
                        username: ev.target.value,
                      });
                      checkValidity('email', ev.target.value);
                    }}
                  />
                  {isTouched &&
                    errorMessage.email &&
                    !errorMessage.email.success && (
                      <label className='error'>
                        {errorMessage.email.errorMessage ||
                          'This field is required'}
                      </label>
                    )}
                </Form.Group>
                <Form.Group className='mb-3' controlId='formPassword'>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Enter Password'
                    onChange={(ev) => {
                      setRequestBody({
                        ...requestBody,
                        password: ev.target.value,
                      });
                      checkValidity('password', ev.target.value);
                    }}
                  />
                  {isTouched &&
                    errorMessage.password &&
                    !errorMessage.password.success && (
                      <label className='error'>
                        {errorMessage.password.errorMessage ||
                          'This field is required'}
                      </label>
                    )}
                </Form.Group>
              </Col>
            </Row>
            <div className='login--btn'>
              <button
                className={`c-btn c-btn-gradient ${
                  isLoading ? 'is--loading' : ''
                } ${SignupDisabled ? 'disabled' : ''}`}
              >
                <UILoader />
                <span className='c-btn-text'>Sign Up Now</span>
              </button>
              <div className='signup-content'>
                <p>
                  Already have an account? <Link to='/login'> Login</Link>
                </p>
              </div>
            </div>
          </Form>
        </Col>

        <Col
          lg='4'
          className='pricing-plans-wrapper singleplan'
          style={{ paddingTop: '100px' }}
        >
          <div className='pricing-plan singleplan'>
            <SinglePlan
              id={currentPlan.id}
              planKey={planNameProp}
              billIsYearly={isYearly}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Registration;
