import React from 'react';
import Registration from '../components/Registration/registration';
import Header from '../components/Header/header';
import { isLoggedIn } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (isLoggedIn()) {
      navigate('/account');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='auth-container register ce-nav-padding'>
      <Header forcedFixed={true} />

      <Registration />
    </div>
  );
};

export default RegisterPage;
