import axios from 'axios';
import { axiosBaseURL } from '../utils/Constants';
import { compareTime } from '../utils/Helper';

class ApiWrapper {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: axiosBaseURL,
      timeout: 4000,
    });
  }

  async headers(applicationToken = true, authorizeToken = true, headers = []) {
    try {
      const token = JSON.parse(localStorage.getItem('session'));
      let appTokenBody = localStorage.getItem('APPLICATION-TOKEN');
      let isValidAppToken = false;

      if (appTokenBody) {
        appTokenBody = JSON.parse(appTokenBody);
        isValidAppToken =
          compareTime(appTokenBody.timestamp, new Date().getTime()) < 10;
      }

      if (authorizeToken) {
        this.axiosInstance.defaults.headers.common[
          'SESSION-TOKEN'
        ] = `${token?.session_token}`;
      }

      if (applicationToken) {
        if (!isValidAppToken) {
          await this.fetchAppToken();
        } else {
          this.axiosInstance.defaults.headers.common[
            'APPLICATION-TOKEN'
          ] = `${appTokenBody.token}`;
        }
      } else {
      }

      for (const [key, value] of Object.entries(headers)) {
        this.axiosInstance.defaults.headers.common[key] = value;
      }

      return Promise.resolve();
    } catch (error) {
      return Promise.resolve();
    }
  }

  async method(type, endPoint, body = null, config = null) {
    if (['post', 'patch', 'put'].includes(type.toLowerCase())) {
      try {
        const response = await this.axiosInstance[type.toLowerCase()](
          endPoint,
          body,
          config
        );
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    } else if (['get', 'head', 'delete'].includes(type.toLowerCase())) {
      try {
        const response = await this.axiosInstance[type.toLowerCase()](
          endPoint,
          config
        );
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    }

    return Promise.reject(`Invalid method type: ${type.toLowerCase()}`);
  }

  async fetchAppToken() {
    try {
      const accessKey = '466A985D-D08C-46FB-8587-BA1BDFF909F0';
      const accessSecret =
        '323CBFED-7421-4807-BCD9-BD2E78FB9075-0F575B2D-78BC-4EF8-B794-437EDCD3506A';
      const requestHeaders = {
        'ACCESS-KEY': accessKey,
        'ACCESS-SECRET': accessSecret,
      };
      let response = await this.method('post', '/generateToken', null, {
        headers: requestHeaders,
      });

      if (response.data && response.data.code === 200) {
        this.axiosInstance.defaults.headers.common[
          'APPLICATION-TOKEN'
        ] = `${response.data.data.token}`;
        const storageBody = {
          token: response.data.data.token,
          timestamp: new Date().getTime(),
        };
        localStorage.setItem('APPLICATION-TOKEN', JSON.stringify(storageBody));
        return Promise.resolve(response);
      }
    } catch (error) {}

    return Promise.reject(`Application token couldn't be fetched!`);
  }
}

export const api = new ApiWrapper();
