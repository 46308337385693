import React from 'react';

import BannerSection from '../components/BannerSection/bannarSection';
import Header from '../components/Header/header';
import AboutSection from '../components/AboutSection/aboutSection';
import Plans from '../components/Plans/plans.js';
import Features from '../components/Features/features';
import ProjectsSection from '../components/ProjectsSection/projectsSection';
// import Testimonials from '../components/Testimonials/testimonials';
// import FAQSection from '../components/FAQSectoin/faqSection';
import ContactSection from '../components/ContactSection/contactSection';
import CompanyInfo from '../components/CompanyInfo/companyInfo';
import Footer from '../components/Footer/Footer';
import PartnerCompanies from '../components/PartnerCompanies/partnerCompanies';

const HomePage = () => {
  return (
    <div className='page-container'>
      <Header />
      <BannerSection />
      <AboutSection />
      <Features />
      <ProjectsSection />
      {/* <Testimonials /> */}
      <Plans />
      {/* <FAQSection /> */}
      <ContactSection />
      <PartnerCompanies />
      <CompanyInfo />
      <Footer />
    </div>
  );
};

export default HomePage;
