import './projectsectioncss.css';
import ThumbnailHuawei_digixlab from '../../assets/images/project-thumbnails/Thumbnail Huawei_digixlab.PNG';
import ThumbnailOmron from '../../assets/images/project-thumbnails/Thumbnail-Omron.png';
import ThumbnailSamsera from '../../assets/images/project-thumbnails/Samsera_Tokidoki.png';
import ThumbnailEpson from '../../assets/images/project-thumbnails/epson.png';
import ThumbnailLoreal from '../../assets/images/project-thumbnails/Loreal.png';
import ThumbnailSM from '../../assets/images/project-thumbnails/SingagporeMaritime.png';
import ThumbnailFamagusta from '../../assets/images/project-thumbnails/famagusta.png';
import ThumbnailVaseline from '../../assets/images/project-thumbnails/Vaseline.png';
import ThumbnailMint from '../../assets/images/project-thumbnails/Mint.png';
import ThumbnailSIFA from '../../assets/images/project-thumbnails/SIFA.PNG';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

function ProjectsSection() {
  const caseStudies = [
    {
      heading: 'OMRON Virtual Showroom',
      body: 'Powered by CloudExpo, this virtual showroom is a great marketing tool where the audience can find out more about Omron’s state of the art robotics and automation solutions.',
      link: 'https://atc-virtual-tour.omron-ap.com',
      thumbnail: ThumbnailOmron,
    },
    {
      heading: 'Tokidoki 3D Showroom',
      body: 'How might Web 3 change the way artists and fashion practitioners present their ideas? Explore how Tokidoki, an internationally recognized and iconic lifestyle brand, pioneered the future of exhibitions.',
      link: 'https://samsera.ai/?tokidoki',
      thumbnail: ThumbnailSamsera,
    },
    {
      heading: 'Epson Virtual Showroom',
      body: 'Powered by CloudExpo, these virtual showrooms are great marketing tools which allow the audience to find out more about Epson’s latest printing solutions.',
      link: 'https://cloudexpo.hiverlab.com/epson/?epson-L1',
      thumbnail: ThumbnailEpson,
    },
    {
      heading:
        'L’Oreal Virtual Career Fair for Campus Recruitment (North Asia and Singapore)',
      body: 'With CloudExpo, Team L’Oreal has built their own virtual career fair metaverses to make L’Oreal’s regional campus recruitment more effective.',
      link: 'https://cloudexpo.hiverlab.com/?LOREALL4YNA',
      thumbnail: ThumbnailLoreal,
    },
    {
      heading: 'Huawei DigiX Lab',
      body: 'With CloudExpo, Huawei has digitized several physical labs on the cloud, to showcase Huawei’s latest technology innovation.',
      link: 'https://cloudexpo.hiverlab.com/?xboYjeg',
      thumbnail: ThumbnailHuawei_digixlab,
    },
    {
      heading: 'Singapore Maritime Gallery Virtual Tour',
      body: 'Explore this educational virtual tour of Singapore Maritime Gallery, to find out more about the past, present and future of Singapore’s maritime sector.',
      link: ' https://www.mpa.gov.sg/cwp/assets/virtualgallery/virtualgallery/index.html',
      thumbnail: ThumbnailSM,
    },
    {
      heading: 'Famagusta Heritage Tour',
      body: 'Explore this educational virtual tour of Famagusta, a city with rich heritage from the Middle Ages. This project was done with the support from global leading scholars in the area of studies, i.e. Art Historian Professor Michael Walsh.',
      link: 'https://cloudexpo.hiverlab.com/Famagusta/?churches',
      thumbnail: ThumbnailFamagusta,
    },
    {
      heading: 'Vaseline Gluta Hya Lab',
      body: 'CloudExpo is proud to support Myanmar’s First Immersive Virtual Experience Campaign, launched by Unilever, Mango Media Group, rangoon.tech and Expa.ai. In the virtual experience called Vaseline Gluta Hya Lab, users can enjoy 360 virtual experience, see celebrities and KOLs in 360 spaces, take selfies with AR filters, explore Vaseline products, buy them with special discounts, test their skin types in the clinic lab and win rewards.',
      link: 'https://cloudexpo.hiverlab.com/?VaselineGlutaHyaLab',
      thumbnail: ThumbnailVaseline,
    },
    {
      heading: 'MINT Museum Of Toys Virtual Tours',
      body: 'MINT Museum of Toys is the largest of its kind in Asia. Explore MINT’s extensive collection of vintage toys and childhood memorabilia from the mid-19th century to mid-20th Century, via the virtual galleries powered by CloudExpo.',
      link: 'https://emint.com/virtual-museum/',
      thumbnail: ThumbnailMint,
    },
    {
      heading: 'Singapore International Festival of Arts (SIFA)',
      body: 'Singapore International Festival of Arts (SIFA) is an annual event that incorporates theater arts, dance, music and visual arts, etc. Watch as their Festival Director, Gaurav Kripalanni takes you on a virtual tour around their art house.',
      link: 'https://cloudexpo.hiverlab.com/?zbqYrbp',
      thumbnail: ThumbnailSIFA,
    },
  ];

  return (
    <section
      id='casestudies'
      className='section-projects section-slide js-section'
    >
      <div className='container'>
        <div
          data-content-title='projects'
          className='section-title section-title-centered'
        >
          <span className='tagline two-lined slide-up'>Case Studies</span>

          <h2 style={{ transitionDelay: '0.6s' }} className='slide-up'>
            <span className='font-thin'>Made with</span>
            <span className='blue-highlight'> Cloud</span>Expo
          </h2>

          <p style={{ transitionDelay: '0.9s' }} className='slide-up'>
            Featured projects created by cloudexpo users
          </p>
        </div>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 8, sm: 12, md: 20 }}
          justifyContent='center'
        >
          {caseStudies.map(({ heading, body, link, thumbnail }, index) => {
            return (
              <Grid
                key={index}
                item
                xs={6}
                sm={5}
                md={4}
                style={{ transitionDelay: '0.9s' }}
                className='slide-up'
              >
                <div
                  style={{ backgroundImage: `url('${thumbnail}')` }}
                  data-content-title={'0' + index}
                  className='project-box'
                >
                  <a
                    href={link}
                    target='_blank'
                    rel='noreferrer'
                    className='project-box-overlay'
                  >
                    &nbsp;
                  </a>
                  <div className='project-box-content'>
                    <h4>{heading}</h4>
                    <hr />
                    <div className='project-box-content-hidden'>
                      <p>{body}</p>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </section>
  );
}

export default ProjectsSection;
