import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size={props.size || 'md'}
      className='ce-plan-modal'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props.modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        {props.primaryBtn && (
          <Button
            className='c-btn c-btn-gradient'
            onClick={props.primaryBtn.handler}
          >
            <span className='c-btn-text'>
              {props.primaryBtn.text || 'Submit'}
            </span>
          </Button>
        )}
        {/* <Button className="c-btn c-btn-gradient" onClick={props.onHide}>
            <span className="c-btn-text">Close</span>
          </Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default MyVerticallyCenteredModal;
