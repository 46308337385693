import { Toaster } from 'react-hot-toast';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { useEffect } from 'react';

import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import HomePage from './pages/Home';
import AccountDetailsPage from './pages/AccountDetails';
import PaymentResultsPage from './pages/PaymentResult';
import Banner3d from './pages/Banner3d';

import { GeneralLogic } from './interceptor/generalLogic';
import 'slick-carousel/slick/slick.css';
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/custom.css';
import NotFound from './pages/NotFound';

function App() {
  console.log(process.env.NODE_ENV);

  const { fetchSessionLocation, initPlanConfiguration } = GeneralLogic();

  useEffect(() => {
    // fetchCurrencyRates();
    fetchSessionLocation();
    initPlanConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <HomePage />,
      errorElement: <NotFound />,
    },
    {
      path: '/login',
      element: <LoginPage />,
      errorElement: <NotFound />,
    },
    {
      path: '/demo',
      element: <Banner3d />,
      errorElement: <NotFound />,
    },
    {
      path: '/register',
      element: <RegisterPage />,
      errorElement: <NotFound />,
    },
    {
      path: '/register/:planType/:billType',
      element: <RegisterPage />,
      errorElement: <NotFound />,
    },
    {
      path: '/register/:planType',
      element: <RegisterPage />,
      errorElement: <NotFound />,
    },
    {
      path: '/account',
      element: <AccountDetailsPage />,
      errorElement: <NotFound />,
    },
    {
      path: '/payment/success',
      element: <PaymentResultsPage />,
      errorElement: <NotFound />,
    },
    {
      path: '/payment/failure',
      element: <PaymentResultsPage />,
      errorElement: <NotFound />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return (
    <div className='main-container'>
      <div>
        <Toaster />
      </div>

      <RouterProvider router={router} />
      {/* <Router>
        <Route path='/' exact component={HomePage} />
        <Route path='/login' exact component={LoginPage} />
        <Route path='/demo' exact component={Banner3d} />
        <Route path='/register' exact component={RegisterPage} />
        <Route path='/register/:planType' exact component={RegisterPage} />
        <Route
          path='/register/:planType/:billType'
          exact
          component={RegisterPage}
        />

        <Route path='/account' exact component={AccountDetailsPage} />
        <Route path='/payment/success' exact component={PaymentResultsPage} />
        <Route path='/payment/failure' exact component={PaymentResultsPage} />
      </Router> */}
    </div>
  );
}

export default App;
