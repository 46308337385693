/* eslint-disable no-useless-escape */
export const axiosBaseURL = process.env.REACT_APP_API_BASE_URL;

export const regexPatterns = {
  url: /[-a-zA-Z0-9@:%._\+~#=]{3,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  email: /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  name: /^[a-zA-Z]+(([-. ][a-zA-Z ])?[a-zA-Z]*)*$/,
  date: /(\b(0?[1-9]|[12]\d|30|31)[^\w\d\r\n:](0?[1-9]|1[0-2])[^\w\d\r\n:](\d{4}|\d{2})\b)/,
  dateUTC:
    /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9]).([0-9][0-9][0-9])(Z)$/,
};

export let isLoggedIn = false;

export let loginToken = null;
