import { GeneralLogic } from '../../interceptor/generalLogic';
import { useState, useEffect } from 'react';
import { formatDate } from '../../utils/Helper';

function SinglePlan(props) {
  const { appPricingPlans, sessionLocation } = GeneralLogic();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const plans = appPricingPlans;

  const currentPlan = plans.find((x) => x.id === props.id);

  let price = props.billIsYearly
    ? currentPlan.usd_prices.yearly / 12
    : currentPlan.usd_prices.monthly; // gets yearly price divided by 12
  let priceOrigin = 'USD $';

  // if (sessionGeoIp.country_name.toLowerCase() == 'singapore') {
  if (sessionLocation && sessionLocation.toLowerCase() === 'singapore') {
    price = props.billIsYearly
      ? currentPlan.sgd_prices.yearly / 12
      : currentPlan.sgd_prices.monthly;
    priceOrigin = 'SGD $';
  }

  const labelText =
    price === 0
      ? 'forever'
      : props.billIsYearly
      ? 'per </br> month'
      : 'per </br> month';

  const keyURL = props.billIsYearly ? 'yearly' : 'monthly';
  const relativePath = `/register/${props.planKey}/${keyURL}`;

  useEffect(() => {
    if (localStorage.getItem('session')) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div
      className={`plan-wrapper plan--${props.planKey} ${
        props.selectedPlan?.plan_id === currentPlan.id
          ? 'plan--is-selected'
          : ''
      }`}
    >
      <div className='plan-header'>
        <h3>{currentPlan.name}</h3>
        {!currentPlan.is_custom_plan && (
          <div className='plan-price'>
            <span className='price-origin'>{priceOrigin}</span>
            <span className='amount'>
              {new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 2,
              }).format(price)}
            </span>
            <span
              className='bill-type'
              dangerouslySetInnerHTML={{ __html: labelText }}
            ></span>
          </div>
        )}

        {/* <span class="plan-price-note">{ props.billIsYearly && price !== 0 ? 'billed annually' : ''}</span> */}
        {currentPlan.is_custom_plan && (
          <p className='card-text'>
            Need a custom solution? We’ve got you covered
          </p>
        )}

        {/* Encourages guest user to purchase plan */}
        {!isLoggedIn && (
          <a
            href={!currentPlan.is_custom_plan ? relativePath : '#contact'}
            className={`c-btn c-btn-gradient ${
              !currentPlan.is_custom_plan ? '' : 'js-smooth-scroll'
            }`}
          >
            <span className='c-btn-text'>{currentPlan.button_text}</span>
          </a>
        )}

        {/* For custom plan */}
        {isLoggedIn && currentPlan.is_custom_plan && (
          <a
            href='#contact'
            onClick={() => {
              props.onSelect(currentPlan.id, true);
            }}
            className={`c-btn c-btn-gradient`}
          >
            <span className='c-btn-text'>{currentPlan.change_text}</span>
          </a>
        )}

        {/* Encourages logged in user to change plan if plan item is not the one active */}
        {isLoggedIn &&
          props.selectedPlan?.plan_id !== currentPlan.id &&
          !currentPlan.is_custom_plan && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              // eslint-disable-next-line no-script-url
              href='javascript:void(0)'
              onClick={() => {
                props.onSelect(
                  currentPlan.id,
                  false,
                  props.selectedPlan?.plan.plan_name !== 'Free'
                );
              }}
              className={`c-btn c-btn-gradient js-smooth-scroll`}
            >
              <span className='c-btn-text'>{currentPlan.change_text}</span>
            </a>
          )}

        {/* Encourages logged in user to cancel plan if plan item is the one activated */}

        {/* Encourages logged in user to cancel plan if plan item is the one activated */}
        {isLoggedIn &&
          props.planStatus === 'cancelled' &&
          props.selectedPlan?.plan_id === currentPlan.id && (
            <span className='text-danger'>
              Current plan will be downgraded to <strong>Free Plan</strong>{' '}
              effective from <br />{' '}
              <strong>
                {formatDate(props.selectedPlan.end_date, 'DD MMM YYYY')}
              </strong>
            </span>
          )}
      </div>

      <ul className='feature-list cf--grouped'>
        {currentPlan.grouped_features.map((x, index) => (
          <li key={index}>
            <span dangerouslySetInnerHTML={{ __html: x.icon }}></span>
            <p>{x.text}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SinglePlan;
