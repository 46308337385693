import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PaymentFailure() {
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelector('body').classList.add('site--loaded');
    let paymentSession = localStorage.getItem('PAYMENT-SESSION');
    if (paymentSession) {
      setTimeout(() => {
        navigate('/account');
      }, 3000);
    } else {
      navigate('/account');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className='section-message payment--error'>
      <div className='message-container'>
        <div className='payment'>
          <svg
            className='payment__error'
            data-name='Capa 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 54 247.28'
          >
            <path d='M27,0A20,20,0,0,0,7,20V148.79a20,20,0,0,0,40,0V20A20,20,0,0,0,27,0Z' />
            <circle cx='27' cy='220.28' r='27' />
          </svg>
          <svg
            className='payment__background'
            height='115'
            viewBox='0 0 120 115'
            width='120'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z'></path>
          </svg>
        </div>

        <div className=''>
          <h2>Payment Failed</h2>

          <p>We couldn't process your payment. Please try again.</p>
        </div>
      </div>
    </section>
  );
}

export default PaymentFailure;
