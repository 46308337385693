import React from 'react';
import Login from '../components/Login/login';
import Header from '../components/Header/header';

import { isLoggedIn } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isLoggedIn()) {
      navigate('/account');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='auth-container login'>
      <Header forcedFixed={true} />

      <Login />
    </div>
  );
};

export default LoginPage;
