import HubspotForm from 'react-hubspot-form';

function ContactSection() {
  return (
    <section id='contact' className='section-contact section-slide js-section'>
      <div className='container'>
        <div
          data-content-title='Contact'
          className='section-title section-title-centered'
        >
          <span className='tagline two-lined slide-up'>Contact</span>

          <h2 style={{ transitionDelay: '0.6s' }} className='slide-up'>
            <span className='font-thin'>Get</span> In{' '}
            <span className='blue-highlight'>Touch</span>
          </h2>

          <p style={{ transitionDelay: '0.9s' }} className='slide-up'>
            Create self-serve virtual tours, embed information, content, and
            interactive functions.
          </p>
        </div>
      </div>

      <div className='contact-wrapper pt-5'>
        <div className='d-flex'>
          <div className='cld-contact-info'>
            <strong>Singapore (Headquarter)</strong>
            <p>
              1008 Toa Payoh North, #04-12/14/15 <br />
              Singapore 318996
            </p>

            <strong>Vietnam Office</strong>
            <p>
              222 Dien Bien Phu Street, Vo Thi Sau Ward, District 3, Ho Chi Minh
              City, Vietnam
            </p>

            <strong>India Office</strong>
            <p>
              4, Floor-1, Plot-68, Mohan Kunj, Mahatma Jyotiba Phule Road,
              Naigaon, Dadar, Mumbai, Mumbai City, Maharashtra, India, 400014
            </p>
          </div>
          <div className='cld-hubspot-form'>
            <HubspotForm
              region='na1'
              portalId='6476345'
              formId='6ed5f4ec-6c7a-4541-89b3-87a0c1f0ec26'
            />
            {/* <script
              charSet="utf-8"
              type="text/javascript"
              src="https://js.hsforms.net/forms/shell.js"
            ></script>
            <script>
              hbspt.forms.create({
                {
                  region: "na1",
                  portalId: "6476345",
                  formId: "6ed5f4ec-6c7a-4541-89b3-87a0c1f0ec26",
                }
              });
            </script> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
